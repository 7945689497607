/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
PAGES
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page -> About
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
main[page=about] {

    @media (min-width:767px) and (min-height:800px) {
        height: calc(100vh - 50px);
    }
}

.wrapper {

    &--about {
        display: grid;


        @media(min-width:1000px) {
            grid-template-columns: 55% auto;
        }

        & .wrapper__placeholder {

            height: 300px;
            grid-row: 1/2;
            width: 100%;
            background-image: url("../images/hero-max.jpg");
            background-position: 50% 30%;
            background-size: cover;

            @media(min-width:600px) {
                height: 400px;
            }

            @media(max-width:999px) {
                margin-bottom: 3rem;
            }

            @media(min-width:1000px) {
                position: fixed;
                top: 0;
                right: 0;
                height: 100%;
                width: 30%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
            }

            @media(min-width:1200px) {
                position: fixed;
                width: 35%;
            }
        }
    }
}


/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page -> Imprint
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
main[page=privacy],
main[page=imprint] {

    .xcontainer {
        max-width: 800px;
        margin: auto;
        padding: 0 1rem;
    }

    h1 {
        font-size: 2.4rem;
        padding-bottom: 2.2rem;
    }

    h2 {
        font-size: 1.6rem;
        padding: 1.4rem 0 1rem;
    }

    h3 {
        font-size: 1.0rem;
        padding: 1rem 0 .8rem;
    }

    & p {
        font-size: .8rem;
        margin-bottom: 1.4rem;
    }

    ul {
        padding: 0 0 1.4rem;

        li {
            font-size: .8rem;
            font-weight: 200;
            list-style: outside;
            margin-left: 1.5rem;
            padding-bottom: .5rem;
        }
    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page -> Projects
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.project-wrap {
    flex-direction: column;
}

.project {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 3rem;

    &__images {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    &__description {
        grid-column: 1/2;
        grid-row: 2/3;
        height: min-content;
        padding-bottom: 3rem;
    }

    &-info {
        display: grid;
        grid-gap: 3rem;
    }

    &__infobox {
        display: flex;
        flex-direction: column;
        width: max-content;
        border: 1px dashed #fff;
        padding: 1rem;
    }

    @media(max-width: $breakpoint--md) {
        grid-row-gap: 3rem;
    }

    @media(min-width: $breakpoint--md) {
        grid-template-columns: minmax(auto, 1fr) minmax(auto, 500px);

        &__images {
            grid-column: 1/2;
            grid-row: 1/2;
        }

        &__description {
            position: sticky;
            top: 80px;

            grid-column: 2/3;
            grid-row: 1/2;
        }

        &-info {
            grid-column: 1/3;
            grid-row: 2/3;
            background: $color--a-1;
            z-index: 1;
        }
    }
}


/* pagination */
.project-pagination {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & a {
        display: flex;
        justify-content: center;
        width: 3rem;
    }

    & .prev {
        grid-column: 1/2;
        justify-self: start;
    }

    & .next {
        grid-column: 2/3;
        justify-self: end;
    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page -> About
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
main[page=contact] {

    @media (min-width:767px) and (min-height:500px) {
        height: calc(100vh - 50px);
    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page -> 404
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.page-not-found {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);

    & h1 {
        padding-bottom: 0.7rem;
    }
}