/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
VARIABLES 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Brakpoints
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

$breakpoint--sm: 768px;
$breakpoint--md: 1025px;
$breakpoint--lg: 1300px;

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Root
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
:root {
    --fs-global: 19.0px;
    --fs-spacing: 20px;

    --fs-h1: 2.60rem;
    --fs-h2: 2.20rem;
    --fs-h3: 1.80rem;
    --fs-h4: 1.40rem;
    --fs-h5: 1.00rem;
    --fs-h6: 0.60rem;

    --fs-sub-h: 1.4rem;

    --fs-p1: .9rem;
    --fs-p2: .75rem;
    --fs-p3: .65rem;

    --fs-menu: 1.1rem;
    --fs-footer: .8rem;

    @media(min-width: $breakpoint--sm) {
        --fs-global: 19.5px;

        --fs-h1: 2.8rem;
        --fs-h2: 2.4rem;
        --fs-h3: 2.0rem;
        --fs-h4: 1.6rem;
        --fs-h5: 1.2rem;
        --fs-h6: 0.8rem;

        --fs-sub-h: 1.3rem;

        --fs-menu: 1.0rem;
    }

    @media(min-width: $breakpoint--md) {
        --fs-h1: 2.9rem;
        --fs-h2: 2.5rem;
        --fs-h3: 2.1rem;
        --fs-h4: 1.7rem;
        --fs-h5: 1.3rem;
        --fs-h6: 0.9rem;

        --fs-sub-h: 1.6rem;

        --fs-p1: .95rem;
        --fs-p2: .80rem;
        --fs-p3: .70rem;
    }

    @media(min-width: $breakpoint--lg) {
        --fs-global: 20px;

        --fs-h1: 3.1rem;
        --fs-h2: 2.7rem;
        --fs-h3: 2.3rem;
        --fs-h4: 1.9rem;
        --fs-h5: 1.5rem;
        --fs-h6: 1.1rem;

        --fs-sub-h: 1.8rem;

        --fs-p1: 1rem;
        --fs-p2: .85rem;
        --fs-p3: .75rem;
    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Colors
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

$color--a-1: #0e0e10;
$color--a-2: #18181b;
$color--a-3: #1f1f23;
$color--b-1: #FFFFFF;
$color--b-2: #B2B2B2;
$color--b-3: #D0D0D0;
$color--b-4: #EAEAEA;
$color--border: #353538;
$color--accent-1: #28e082;