/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
STYLES 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Page Loader
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

.preloader {
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    right: 0;
    background: $color--a-2;
    z-index: 9;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

@keyframes preload {
    0% {
        transform: translateX(-100%)
    }

    35% {
        transform: translateX(0%)
    }

    65% {
        transform: translateX(0%)
    }

    100% {
        transform: translateX(100%)
    }
}

.overlay {
    animation: preload 2s infinite;
}


.box1 {
    display: block;
    background: red;
    width: 200px;
    height: 200px;
    z-index: 999;
    position: fixed;
    overflow: hidden;
}

.box {
    display: block;
    background: $color--a-2;
    height: 100%;
    width: 100%;
    left: -100vw;
    z-index: 999;
    position: fixed;
    overflow: hidden;
}