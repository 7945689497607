/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
FOOTER 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

footer {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $color--a-2;
    border-top: 1px solid $color--border;
    z-index: 99;
    min-height: 49px;

    & .container {
        width: 100%;
        padding: 0 1rem;
    }

    .footer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 0;
    }

    .legal-info,
    .legal-links {
        display: flex;

    }

    a,
    p {
        font-size: 12px;
        line-height: 1;
    }

    .legal-info p,
    .legal-info a,
    .legal-links a {
        color: $color--b-2;
        font-weight: 300;
    }

    .legal-info a:hover,
    .legal-links a:hover {
        color: $color--accent-1;
    }

    .legal-links a:first-child {}

    @media(max-width: 480px) {
        .footer-content {
            flex-direction: column-reverse;

            & .legal-info,
            & .legal-links {
                width: 100%;
                justify-content: center;
                display: flex;
            }

            & .legal-links {
                margin-bottom: .5rem;
            }
        }
    }
}

.separator {
    font-size: 12px;
    margin: 0 .3rem;
    cursor: default;
    color: $color--b-2;
}