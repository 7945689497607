/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
FONTS 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Fonts (self hosted)
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-100.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-100-italic.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-200.woff) format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-200-italic.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-300.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-300-italic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-400.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-400-italic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-500.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-500-italic.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-600.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-600-italic.woff) format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-700.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-700-italic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-800.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-800-italic.woff) format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-900.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(/fonts/Poppins/poppins-900-italic.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}