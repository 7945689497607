/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
MENU 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

.nav-flow {
    overflow-y: hidden;
}

.navbar {
    z-index: 100;

    @media(max-width: 799px) {

        &-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            background: $color--a-2;
            width: calc(100% - 2rem);
            height: 59px;
            border-bottom: 1px solid $color--border;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1rem;
            z-index: 10;
        }

        &__logo {

            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
                height: 35px;
            }

            &:hover path {
                fill: $color--accent-1;
            }

            &:hover span {
                color: $color--accent-1;
            }
        }

    }

    // Desktop
    @media(min-width: 800px) {
        width: 219px;
        height: 100vh;
        position: fixed;
        background: $color--a-2;
        border-right: 1px solid $color--border;
        display: flex;
        flex-direction: column;

        &-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
        }

        &__logo {

            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 1rem;
            border-bottom: 1px solid $color--border;

            &:hover {
                background: $color--a-3;
            }

            svg {
                height: 65px;
                padding-bottom: .5rem;
            }

            &:hover path {
                fill: $color--accent-1;
            }

            &:hover span {
                color: $color--accent-1;
            }

            @media(max-height: 500px) {

                padding: 1rem 1rem;

                svg {
                    height: 45px;
                }

                span {
                    display: none;
                }
            }
        }

        &__menu {
            @media screen and (min-width: 800px) {
                display: flex;
                flex-direction: column;
                align-items: center;

                &__item {
                    display: flex;
                    width: 100%;

                    &-link {
                        padding: .75rem 1rem;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        border-bottom: 1px solid $color--border;

                        &:hover {
                            color: $color--accent-1;
                            background: $color--a-3;
                        }
                    }

                }
            }

            @media(max-height: 500px) {
                margin-bottom: 4rem;
            }

        }
    }

    &__icon {
        cursor: pointer;

        &:hover svg {
            fill: $color--accent-1;
        }

        & svg {
            width: 24px;
            height: 24px;
            margin-top: 3px;
        }

        @media screen and (min-width: 800px) {
            display: none;
        }
    }

    &__menu {

        // mobile menu
        @media screen and (max-width: 799px) {
            position: absolute;
            display: flex;
            flex-flow: column;
            width: 100%;
            height: 100vh;
            background: $color--a-2;

            top: 100vh;
            left: 0;

            opacity: 1;
            transition: all .3s ease;

            &__item {
                height: 5rem;
                width: 100%;

                &:hover {
                    background: $color--a-3;
                }

                &-link {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        color: $color--accent-1;
                    }
                }

            }

            &.active {
                top: 0;
                margin-top: 60px;
                transition: all .3s ease;
            }
        }

        // desktop menu
        @media screen and (min-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__item {
                display: flex;
                width: 100%;

                &-link {
                    padding: .8rem 1rem;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    border-bottom: 1px solid $color--border;

                    &:hover {
                        color: $color--accent-1;
                    }
                }

            }
        }

    }


}

// wir sollten mit Toogle-Class arbeiten und das mal testen
// https://codepen.io/kalpeshsingh/pen/OjNWer

// https://dev.to/devggaurav/let-s-build-a-responsive-navbar-and-hamburger-menu-using-html-css-and-javascript-4gci
// https://github.com/dev-ggaurav/responsive-hamburger-tutorial

// https://www.youtube.com/watch?v=T2MhVxJxsL0&t=71s
// https://github.com/briancodex/react-navbar-dropdown