/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
----------------------------------------------------------------------------------------------------------------------------------------------------------------
BUTTONS 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/
.btn {
    display: inline-block;
    font-weight: 400;
    border: 2px solid;
    text-transform: uppercase;

    &--1 {
        font-size: var(--fs-p1);
        padding: .9rem 2rem;
    }

    &--2 {
        font-size: var(--fs-p2);
        padding: .80rem 1.8rem;
    }

    &--3 {
        font-size: var(--fs-p3);
        padding: .7rem 1.6rem;
    }

    &--a {
        color: $color--accent-1;
        border-color: $color--accent-1;
        transition: ease-in .075s;

        &:hover {
            background: $color--accent-1;
            color: $color--a-1;
            font-weight: 400;
        }
    }
}