/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
STYLES 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

@import 'normalize.css';
@import './styles/fonts';
@import './styles/variables';
@import './styles/modifiers';
@import './styles/pages';
@import './styles/components';
@import './styles/btns';
@import './styles/menu';
@import './styles/footer';
@import './styles/404';
@import './styles/animations';


/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Layout Breakpoints
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

$breakpoint--grid-sm: 600px;
$breakpoint--grid-md: 1000px;

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Basic Styles
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


// Scrollbar Styles
@supports(scrollbar-color: red green) {
  html {
    scrollbar-color: $color--border $color--a-1;
  }

  html {
    @media(min-width: 800px) {
      border-right: 1px solid $color--border;
    }
  }
}

html {
  &::-webkit-scrollbar {
    width: .8rem;
  }

  &::-webkit-scrollbar-track {
    background: $color--a-1;
    border-left: 1px solid $color--border;
  }

  &::-webkit-scrollbar-thumb {
    background: $color--border;
  }
}


html {
  font-size: 100%;
  font-size: var(--fs-global);
}

body {
  background: $color--a-2;
  color: $color--b-1;
  font-family: 'Poppins';
}

#root {
  background: $color--a-1;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}



/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Typography
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 100%;
}

h1 {
  font-size: initial;
  font-weight: 700;
  letter-spacing: .05rem;
}

h2 {
  font-size: initial;
  font-weight: 600;
  letter-spacing: .05rem;
}

h3 {
  font-size: initial;
  font-weight: 600;
  letter-spacing: .05rem;
}

h4 {
  font-size: initial;
  font-weight: 600;
  letter-spacing: .05rem;
}

h5 {
  font-size: initial;
  font-weight: 600;
  letter-spacing: .05rem;
}

h6 {
  font-size: initial;
  font-weight: 600;
  letter-spacing: .05rem;
}

p {
  font-size: initial;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 200;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: $color--b-1;
}


/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Content / Components
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

//main[page=homepage]
main {
  position: relative;

  a {

    &:hover {
      color: $color--accent-1;
    }
  }
}

// Main Grid
.gfk-grid {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-columns: 20% 20% 20% 40%;
  grid-template-rows: auto;

  @media(min-width:$breakpoint--grid-md) {
    grid-template-rows: 45% 10% 45%;
  }

  overflow: hidden;

  @media(min-height:800px) and (min-width:1200px) {
    height: calc(100vh - 50px);
  }

  &>div {
    position: relative;
    overflow: hidden;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    grid-column: 1/5;
    grid-row: 1/2;

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 1/4;
      grid-row: 1/2;
    }


    & svg {
      max-width: 525px;
      fill: $color--b-1;
    }
  }

  &__insta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    font-size: clamp(1.2rem, 4vw, 1.8rem);
    font-weight: 200;
    border-top: 1px solid $color--border;
    border-bottom: 1px solid $color--border;


    grid-column: 1/5;
    grid-row: 2/3;

    @media(min-width:767) {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 1/4;
      grid-row: 2/3;
    }
  }

  // hero image
  &__hero-img {
    grid-column: 1/5;

    @media(min-width: $breakpoint--grid-sm) {
      grid-column: 3/5;
      grid-row: 3/6;
    }

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 4/5;
      grid-row: 1/4;
    }

    & img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  // letters
  &__letter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    font-size: clamp(3rem, 12vw, 9rem);
    font-weight: 700;
    text-transform: uppercase;
    padding: 2rem;
    cursor: pointer;
    transition: ease-in .033s;
  }

  // letter m
  &__letter-m {
    grid-column: span 4;

    @media(min-width: $breakpoint--grid-sm) {
      grid-column: 1/3;
    }

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 1/2;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%;
      background-image: url("../src/images/logo--glory-for-korn--2.jpg");
      background-size: cover;
      background-position: 50% 50%;
      transition: ease-in 0.25s;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1;
      transition: ease-in 0.25s;
    }
  }

  // letter a
  &__letter-a {
    grid-column: span 4;

    @media(min-width: $breakpoint--grid-sm) {
      grid-column: 1/3;
    }

    @media(max-width:999px) {
      border-top: 1px solid $color--border;
      border-bottom: 1px solid $color--border;
    }

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 2/3;

      border-left: 1px solid $color--border;
      border-right: 1px solid $color--border;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%;
      background-image: url("../src/images/logo--glory-for-korn--1.jpg");
      background-size: cover;
      background-position: 50% 50%;
      transition: ease-in 0.25s;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1;
      transition: ease-in 0.25s;
    }
  }

  // letter x
  &__letter-x {
    grid-column: span 4;

    @media(min-width: $breakpoint--grid-sm) {
      grid-column: 1/3;
    }

    @media(min-width: $breakpoint--grid-md) {
      grid-column: 3/4;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%;
      background-image: url("../src/images/logo--glory-for-korn--3.jpg");
      background-size: cover;
      background-position: 50% 50%;
      transition: ease-in 0.25s;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1;
      transition: ease-in 0.25s;
    }
  }

}