.glitch {
    color: #fff;
    font-size: clamp(6rem, 15vw, 15rem);
    position: relative;
    font-family: 'Roboto';
    font-weight: 700;
}

.glitch::before,
.glitch::after {
    content: attr(data-title);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: $color--a-1;
    color: #fff;
}

.glitch::before {
    left: 8px;
    text-shadow: 2px 0 #00ffea;
    animation: glitch 3s infinite linear;
}

.glitch::after {
    left: 8px;
    text-shadow: -2px 0 #fe3a7f;
    animation: glitch 2s infinite linear;
}

@-moz-keyframes glitch {
    0% {
        clip: rect(4px, 9999px, 36px, 0);
    }

    5% {
        clip: rect(12px, 9999px, 87px, 0);
    }

    10% {
        clip: rect(2px, 9999px, 22px, 0);
    }

    15% {
        clip: rect(17px, 9999px, 71px, 0);
    }

    20% {
        clip: rect(89px, 9999px, 14px, 0);
    }

    25% {
        clip: rect(27px, 9999px, 92px, 0);
    }

    30% {
        clip: rect(71px, 9999px, 38px, 0);
    }

    35% {
        clip: rect(56px, 9999px, 10px, 0);
    }

    40% {
        clip: rect(16px, 9999px, 24px, 0);
    }

    45% {
        clip: rect(96px, 9999px, 35px, 0);
    }

    50% {
        clip: rect(48px, 9999px, 79px, 0);
    }

    55% {
        clip: rect(89px, 9999px, 88px, 0);
    }

    60% {
        clip: rect(65px, 9999px, 55px, 0);
    }

    65% {
        clip: rect(14px, 9999px, 92px, 0);
    }

    70% {
        clip: rect(55px, 9999px, 98px, 0);
    }

    75% {
        clip: rect(98px, 9999px, 97px, 0);
    }

    80% {
        clip: rect(47px, 9999px, 24px, 0);
    }

    85% {
        clip: rect(94px, 9999px, 29px, 0);
    }

    90% {
        clip: rect(62px, 9999px, 94px, 0);
    }

    95% {
        clip: rect(19px, 9999px, 27px, 0);
    }

    100% {
        clip: rect(22px, 9999px, 84px, 0);
    }
}

@-webkit-keyframes glitch {
    0% {
        clip: rect(4px, 9999px, 36px, 0);
    }

    5% {
        clip: rect(12px, 9999px, 87px, 0);
    }

    10% {
        clip: rect(2px, 9999px, 22px, 0);
    }

    15% {
        clip: rect(17px, 9999px, 71px, 0);
    }

    20% {
        clip: rect(89px, 9999px, 14px, 0);
    }

    25% {
        clip: rect(27px, 9999px, 92px, 0);
    }

    30% {
        clip: rect(71px, 9999px, 38px, 0);
    }

    35% {
        clip: rect(56px, 9999px, 10px, 0);
    }

    40% {
        clip: rect(16px, 9999px, 24px, 0);
    }

    45% {
        clip: rect(96px, 9999px, 35px, 0);
    }

    50% {
        clip: rect(48px, 9999px, 79px, 0);
    }

    55% {
        clip: rect(89px, 9999px, 88px, 0);
    }

    60% {
        clip: rect(65px, 9999px, 55px, 0);
    }

    65% {
        clip: rect(14px, 9999px, 92px, 0);
    }

    70% {
        clip: rect(55px, 9999px, 98px, 0);
    }

    75% {
        clip: rect(98px, 9999px, 97px, 0);
    }

    80% {
        clip: rect(47px, 9999px, 24px, 0);
    }

    85% {
        clip: rect(94px, 9999px, 29px, 0);
    }

    90% {
        clip: rect(62px, 9999px, 94px, 0);
    }

    95% {
        clip: rect(19px, 9999px, 27px, 0);
    }

    100% {
        clip: rect(22px, 9999px, 84px, 0);
    }
}

@-o-keyframes glitch {
    0% {
        clip: rect(4px, 9999px, 36px, 0);
    }

    5% {
        clip: rect(12px, 9999px, 87px, 0);
    }

    10% {
        clip: rect(2px, 9999px, 22px, 0);
    }

    15% {
        clip: rect(17px, 9999px, 71px, 0);
    }

    20% {
        clip: rect(89px, 9999px, 14px, 0);
    }

    25% {
        clip: rect(27px, 9999px, 92px, 0);
    }

    30% {
        clip: rect(71px, 9999px, 38px, 0);
    }

    35% {
        clip: rect(56px, 9999px, 10px, 0);
    }

    40% {
        clip: rect(16px, 9999px, 24px, 0);
    }

    45% {
        clip: rect(96px, 9999px, 35px, 0);
    }

    50% {
        clip: rect(48px, 9999px, 79px, 0);
    }

    55% {
        clip: rect(89px, 9999px, 88px, 0);
    }

    60% {
        clip: rect(65px, 9999px, 55px, 0);
    }

    65% {
        clip: rect(14px, 9999px, 92px, 0);
    }

    70% {
        clip: rect(55px, 9999px, 98px, 0);
    }

    75% {
        clip: rect(98px, 9999px, 97px, 0);
    }

    80% {
        clip: rect(47px, 9999px, 24px, 0);
    }

    85% {
        clip: rect(94px, 9999px, 29px, 0);
    }

    90% {
        clip: rect(62px, 9999px, 94px, 0);
    }

    95% {
        clip: rect(19px, 9999px, 27px, 0);
    }

    100% {
        clip: rect(22px, 9999px, 84px, 0);
    }
}

@keyframes glitch {
    0% {
        clip: rect(4px, 9999px, 36px, 0);
    }

    5% {
        clip: rect(12px, 9999px, 87px, 0);
    }

    10% {
        clip: rect(2px, 9999px, 22px, 0);
    }

    15% {
        clip: rect(17px, 9999px, 71px, 0);
    }

    20% {
        clip: rect(89px, 9999px, 14px, 0);
    }

    25% {
        clip: rect(27px, 9999px, 92px, 0);
    }

    30% {
        clip: rect(71px, 9999px, 38px, 0);
    }

    35% {
        clip: rect(56px, 9999px, 10px, 0);
    }

    40% {
        clip: rect(16px, 9999px, 24px, 0);
    }

    45% {
        clip: rect(96px, 9999px, 35px, 0);
    }

    50% {
        clip: rect(48px, 9999px, 79px, 0);
    }

    55% {
        clip: rect(89px, 9999px, 88px, 0);
    }

    60% {
        clip: rect(65px, 9999px, 55px, 0);
    }

    65% {
        clip: rect(14px, 9999px, 92px, 0);
    }

    70% {
        clip: rect(55px, 9999px, 98px, 0);
    }

    75% {
        clip: rect(98px, 9999px, 97px, 0);
    }

    80% {
        clip: rect(47px, 9999px, 24px, 0);
    }

    85% {
        clip: rect(94px, 9999px, 29px, 0);
    }

    90% {
        clip: rect(62px, 9999px, 94px, 0);
    }

    95% {
        clip: rect(19px, 9999px, 27px, 0);
    }

    100% {
        clip: rect(22px, 9999px, 84px, 0);
    }
}