/* 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
MODIFIERS 
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------------------------------------------------------------------------------
*/

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Font Sizes
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

.fs {
    &--h1 {
        font-size: var(--fs-h1);
    }

    &--h2 {
        font-size: var(--fs-h2);
    }

    &--h3 {
        font-size: var(--fs-h3);
    }

    &--h4 {
        font-size: var(--fs-h4);
    }

    &--h5 {
        font-size: var(--fs-h5);
    }

    &--h6 {
        font-size: var(--fs-h6);
    }

    &--sub-h {
        font-size: var(--sub-h);
    }

    &--p1 {
        font-size: var(--fs-p1);
    }

    &--p2 {
        font-size: var(--fs-p2);
    }

    &--p3 {
        font-size: var(--fs-p3);
    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Font -- Text Transform
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.tt {
    &--upper {
        text-transform: uppercase;
    }
}


/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Padding
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

// vielleicht diesen Ansatz für Spacing
.test {
    &--1 {
        padding-bottom: calc(var(--fs-spacing) * 0.25);
    }

    &--2 {
        padding-bottom: calc(var(--fs-spacing) * 0.50);
    }

    &--3 {
        padding-bottom: calc(var(--fs-spacing) * 0.75);
    }

    &--4 {
        padding-bottom: calc(var(--fs-spacing) * 1.0);
    }
}

.p {

    &t {
        &--25 {
            padding-top: 0.25rem;
        }

        &--50 {
            padding-top: 0.50rem;
        }

        &--75 {
            padding-top: 0.75rem;
        }

        &--100 {
            padding-top: 1.00rem;
        }

        &--125 {
            padding-top: 1.25rem;
        }

        &--150 {
            padding-top: 1.50rem;
        }

        &--175 {
            padding-top: 1.75rem;
        }

        &--200 {
            padding-top: 2.00rem;
        }

        &--225 {
            padding-top: 2.25rem;
        }

        &--250 {
            padding-top: 2.50rem;
        }

        &--275 {
            padding-top: 2.75rem;
        }

        &--300 {
            padding-top: 3.00rem;
        }

        &--325 {
            padding-top: 3.25rem;
        }

        &--350 {
            padding-top: 3.50rem;
        }

        &--375 {
            padding-top: 3.75rem;
        }

        &--400 {
            padding-top: 4.00rem;
        }

        &--425 {
            padding-top: 4.25rem;
        }

        &--450 {
            padding-top: 4.50rem;
        }

        &--475 {
            padding-top: 4.75rem;
        }

        &--500 {
            padding-top: 5.00rem;
        }

        &--525 {
            padding-top: 5.25rem;
        }

        &--550 {
            padding-top: 5.50rem;
        }

        &--575 {
            padding-top: 5.75rem;
        }

        &--600 {
            padding-top: 6.00rem;
        }

    }

    &r {
        &--25 {
            padding-right: 0.25rem;
        }

        &--50 {
            padding-right: 0.50rem;
        }

        &--75 {
            padding-right: 0.75rem;
        }

        &--100 {
            padding-right: 1.00rem;
        }

        &--125 {
            padding-right: 1.25rem;
        }

        &--150 {
            padding-right: 1.50rem;
        }

        &--175 {
            padding-right: 1.75rem;
        }

        &--200 {
            padding-right: 2.00rem;
        }

        &--225 {
            padding-right: 2.25rem;
        }

        &--250 {
            padding-right: 2.50rem;
        }

        &--275 {
            padding-right: 2.75rem;
        }

        &--300 {
            padding-right: 3.00rem;
        }

        &--325 {
            padding-right: 3.25rem;
        }

        &--350 {
            padding-right: 3.50rem;
        }

        &--375 {
            padding-right: 3.75rem;
        }

        &--400 {
            padding-right: 4.00rem;
        }

        &--425 {
            padding-right: 4.25rem;
        }

        &--450 {
            padding-right: 4.50rem;
        }

        &--475 {
            padding-right: 4.75rem;
        }

        &--500 {
            padding-right: 5.00rem;
        }

        &--525 {
            padding-right: 5.25rem;
        }

        &--550 {
            padding-right: 5.50rem;
        }

        &--575 {
            padding-right: 5.75rem;
        }

        &--600 {
            padding-right: 6.00rem;
        }
    }

    &b {
        &--25 {
            padding-bottom: 0.25rem;
        }

        &--50 {
            padding-bottom: 0.50rem;
        }

        &--75 {
            padding-bottom: 0.75rem;
        }

        &--100 {
            padding-bottom: 1.00rem;
        }

        &--125 {
            padding-bottom: 1.25rem;
        }

        &--150 {
            padding-bottom: 1.50rem;
        }

        &--175 {
            padding-bottom: 1.75rem;
        }

        &--200 {
            padding-bottom: 2.00rem;
        }

        &--225 {
            padding-bottom: 2.25rem;
        }

        &--250 {
            padding-bottom: 2.50rem;
        }

        &--275 {
            padding-bottom: 2.75rem;
        }

        &--300 {
            padding-bottom: 3.00rem;
        }

        &--325 {
            padding-bottom: 3.25rem;
        }

        &--350 {
            padding-bottom: 3.50rem;
        }

        &--375 {
            padding-bottom: 3.75rem;
        }

        &--400 {
            padding-bottom: 4.00rem;
        }

        &--425 {
            padding-bottom: 4.25rem;
        }

        &--450 {
            padding-bottom: 4.50rem;
        }

        &--475 {
            padding-bottom: 4.75rem;
        }

        &--500 {
            padding-bottom: 5.00rem;
        }

        &--525 {
            padding-bottom: 5.25rem;
        }

        &--550 {
            padding-bottom: 5.50rem;
        }

        &--575 {
            padding-bottom: 5.75rem;
        }

        &--600 {
            padding-bottom: 6.00rem;
        }

    }

    &l {
        &--25 {
            padding-left: 0.25rem;
        }

        &--50 {
            padding-left: 0.50rem;
        }

        &--75 {
            padding-left: 0.75rem;
        }

        &--100 {
            padding-left: 1.00rem;
        }

        &--125 {
            padding-left: 1.25rem;
        }

        &--150 {
            padding-left: 1.50rem;
        }

        &--175 {
            padding-left: 1.75rem;
        }

        &--200 {
            padding-left: 2.00rem;
        }

        &--225 {
            padding-left: 2.25rem;
        }

        &--250 {
            padding-left: 2.50rem;
        }

        &--275 {
            padding-left: 2.75rem;
        }

        &--300 {
            padding-left: 3.00rem;
        }

        &--325 {
            padding-left: 3.25rem;
        }

        &--350 {
            padding-left: 3.50rem;
        }

        &--375 {
            padding-left: 3.75rem;
        }

        &--400 {
            padding-left: 4.00rem;
        }

        &--425 {
            padding-left: 4.25rem;
        }

        &--450 {
            padding-left: 4.50rem;
        }

        &--475 {
            padding-left: 4.75rem;
        }

        &--500 {
            padding-left: 5.00rem;
        }

        &--525 {
            padding-left: 5.25rem;
        }

        &--550 {
            padding-left: 5.50rem;
        }

        &--575 {
            padding-left: 5.75rem;
        }

        &--600 {
            padding-left: 6.00rem;
        }

    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Margin
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.m {

    &t {
        &--25 {
            margin-top: 0.25rem;
        }

        &--50 {
            margin-top: 0.50rem;
        }

        &--75 {
            margin-top: 0.75rem;
        }

        &--100 {
            margin-top: 1.00rem;
        }

        &--125 {
            margin-top: 1.25rem;
        }

        &--150 {
            margin-top: 1.50rem;
        }

        &--175 {
            margin-top: 1.75rem;
        }

        &--200 {
            margin-top: 2.00rem;
        }

        &--225 {
            margin-top: 2.25rem;
        }

        &--250 {
            margin-top: 2.50rem;
        }

        &--275 {
            margin-top: 2.75rem;
        }

        &--300 {
            margin-top: 3.00rem;
        }

        &--325 {
            margin-top: 3.25rem;
        }

        &--350 {
            margin-top: 3.50rem;
        }

        &--375 {
            margin-top: 3.75rem;
        }

        &--400 {
            margin-top: 4.00rem;
        }

        &--425 {
            margin-top: 4.25rem;
        }

        &--450 {
            margin-top: 4.50rem;
        }

        &--475 {
            margin-top: 4.75rem;
        }

        &--500 {
            margin-top: 5.00rem;
        }

        &--525 {
            margin-top: 5.25rem;
        }

        &--550 {
            margin-top: 5.50rem;
        }

        &--575 {
            margin-top: 5.75rem;
        }

        &--600 {
            margin-top: 6.00rem;
        }

    }

    &r {
        &--25 {
            margin-right: 0.25rem;
        }

        &--50 {
            margin-right: 0.50rem;
        }

        &--75 {
            margin-right: 0.75rem;
        }

        &--100 {
            margin-right: 1.00rem;
        }

        &--125 {
            margin-right: 1.25rem;
        }

        &--150 {
            margin-right: 1.50rem;
        }

        &--175 {
            margin-right: 1.75rem;
        }

        &--200 {
            margin-right: 2.00rem;
        }

        &--225 {
            margin-right: 2.25rem;
        }

        &--250 {
            margin-right: 2.50rem;
        }

        &--275 {
            margin-right: 2.75rem;
        }

        &--300 {
            margin-right: 3.00rem;
        }

        &--325 {
            margin-right: 3.25rem;
        }

        &--350 {
            margin-right: 3.50rem;
        }

        &--375 {
            margin-right: 3.75rem;
        }

        &--400 {
            margin-right: 4.00rem;
        }

        &--425 {
            margin-right: 4.25rem;
        }

        &--450 {
            margin-right: 4.50rem;
        }

        &--475 {
            margin-right: 4.75rem;
        }

        &--500 {
            margin-right: 5.00rem;
        }

        &--525 {
            margin-right: 5.25rem;
        }

        &--550 {
            margin-right: 5.50rem;
        }

        &--575 {
            margin-right: 5.75rem;
        }

        &--600 {
            margin-right: 6.00rem;
        }
    }

    &b {
        &--25 {
            margin-bottom: 0.25rem;
        }

        &--50 {
            margin-bottom: 0.50rem;
        }

        &--75 {
            margin-bottom: 0.75rem;
        }

        &--100 {
            margin-bottom: 1.00rem;
        }

        &--125 {
            margin-bottom: 1.25rem;
        }

        &--150 {
            margin-bottom: 1.50rem;
        }

        &--175 {
            margin-bottom: 1.75rem;
        }

        &--200 {
            margin-bottom: 2.00rem;
        }

        &--225 {
            margin-bottom: 2.25rem;
        }

        &--250 {
            margin-bottom: 2.50rem;
        }

        &--275 {
            margin-bottom: 2.75rem;
        }

        &--300 {
            margin-bottom: 3.00rem;
        }

        &--325 {
            margin-bottom: 3.25rem;
        }

        &--350 {
            margin-bottom: 3.50rem;
        }

        &--375 {
            margin-bottom: 3.75rem;
        }

        &--400 {
            margin-bottom: 4.00rem;
        }

        &--425 {
            margin-bottom: 4.25rem;
        }

        &--450 {
            margin-bottom: 4.50rem;
        }

        &--475 {
            margin-bottom: 4.75rem;
        }

        &--500 {
            margin-bottom: 5.00rem;
        }

        &--525 {
            margin-bottom: 5.25rem;
        }

        &--550 {
            margin-bottom: 5.50rem;
        }

        &--575 {
            margin-bottom: 5.75rem;
        }

        &--600 {
            margin-bottom: 6.00rem;
        }

    }

    &l {
        &--25 {
            margin-left: 0.25rem;
        }

        &--50 {
            margin-left: 0.50rem;
        }

        &--75 {
            margin-left: 0.75rem;
        }

        &--100 {
            margin-left: 1.00rem;
        }

        &--125 {
            margin-left: 1.25rem;
        }

        &--150 {
            margin-left: 1.50rem;
        }

        &--175 {
            margin-left: 1.75rem;
        }

        &--200 {
            margin-left: 2.00rem;
        }

        &--225 {
            margin-left: 2.25rem;
        }

        &--250 {
            margin-left: 2.50rem;
        }

        &--275 {
            margin-left: 2.75rem;
        }

        &--300 {
            margin-left: 3.00rem;
        }

        &--325 {
            margin-left: 3.25rem;
        }

        &--350 {
            margin-left: 3.50rem;
        }

        &--375 {
            margin-left: 3.75rem;
        }

        &--400 {
            margin-left: 4.00rem;
        }

        &--425 {
            margin-left: 4.25rem;
        }

        &--450 {
            margin-left: 4.50rem;
        }

        &--475 {
            margin-left: 4.75rem;
        }

        &--500 {
            margin-left: 5.00rem;
        }

        &--525 {
            margin-left: 5.25rem;
        }

        &--550 {
            margin-left: 5.50rem;
        }

        &--575 {
            margin-left: 5.75rem;
        }

        &--600 {
            margin-left: 6.00rem;
        }

    }
}

/* 
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
Positioning
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.flex {
    display: flex;
}

.flex-jc--c {
    justify-content: center;
}

/* info:
we want to adjust the global font size, so the padding and margins adjust with the breakpoints aswell!
*/